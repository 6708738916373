@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,900");

.rec1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  padding: 7.5%;
  padding-top: 5vh;
  background-color: #f5f7fa;
}
.rec1-main {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
}
.rec1-img {
  height: 100%;
  display: flex;
  justify-content: center;
  width: 55%;
}

.rec1-img img {
  height: 60%;
  width: 70%;
}
.rec1-content {
  display: flex;
  flex-direction: column;
  width: 45%;
  height: fit;
}

.rec1-content-header {
  width: 90%;
  font-family: Poppins, sans-serif;
  font-weight: 900;
  text-align: left;
  line-height: 110%;
  padding-left: 2%;
  padding-bottom: 5%;
  font-size: 3.6vw;
  color: #4d4d4d;
}
.rec1-content-subtext {
  width: 100%;
  padding-left: 2%;
  font-family: Poppins, sans-serif;
  font-weight: 300;
  text-align: left;
  font-size: 1.6vw;
  color: #717171;
}
.rec1-join-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 4vw;
}

.input-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 23vw;
}
.rec1-join-box input {
  height: 3vw;
  width: 100%;
  padding-left: 5%;
  border-width: 1px;
  border-radius: 8px;
}

.rec1-join-box input::placeholder {
  font-size: 0.8vw;
  width: 100%;
  font-weight: 700;
}

.rec1-join-box button {
  border-width: 1px;
  height: 3vw;
  border-radius: 8px;
  margin-left: 5%;
  width: fit-content;
  font-weight: 700;
  padding-left: 5%;
  padding-right: 5%;
  box-sizing: border-box;
  font-size: 1.5vw;
  color: white;
  background-color: #0247fe;
}

.PhoneInput{
  width: 100%;
}

.rec1-verified-section{
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rec1-check-img{
  width: 5em;
}

.rec1-verified-text{
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  color: #4d4d4d;
  margin-left: 1em;

}

.rec1-mintosh-logo {
  width: 20vw;
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 3vh;
  font-family: Poppins, sans-serif;
}

.rec1-mintosh-logo img {
  margin-left: 1vw;
  height: 7vw;
  width: 7vw;
}

.alert-message {
  font-family: Poppins, sans-serif;
  display: flex;
  font-weight: 700;
  padding-left: 4%;
  height: 1vw;
}
.alert-message.error {
  color: red;
}

.alert-message.success {
  color: green;
}

.rec1-signup-section {
  display: flex;
  flex-direction: column;
  width: 43%;
}

.rec1-section-2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 5%;
}

.rec1-call-to-join-msg {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  color: #4d4d4d;
}

.social-signup {
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-top: 5%;
  justify-content: center;
}

.SocialSignInButton {
  width: 20vw;
  height: 3vw;
  padding-top: 1%;
  padding-bottom: 1%;
  margin-bottom: 2vh;
  background-color: white;
  border: 0.75px solid black;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-left: 10%;
  text-decoration: none;
  font-weight: 700;
  color: black;
  font-size: 1vw;
  font-family: "Poppins", sans-serif;
}

.SocialSignInButton img {
  height: 75%;
  padding-right: 10%;
  max-height: 25px;
}

/* border-bottom: #4d4d4d solid 1px;
*/
.rec1-stat-section {
  display: flex;
  width: 70%;
  justify-content: right;
}

.stat-box {
  width: 65%;
  height: fit-content;
  background-color: #ffffff;
  padding: 1em;
  display: flex;
  font-size: 1.5vw;
  flex-direction: row;
  font-family: "Poppins", sans-serif;
  border-radius: 8px;
  font-weight: 600;
  color: #0247fe;
}

.stat-box-users-count {
  display: flex;
  flex-direction: column;
}

.stat-box-user-count-number {
  font-size: 4em;
  line-height: 90%;
  font-family: 600;
}

.stat-box-text {
  margin-left: 10px;
}

.social-signup h3 {
  color: #4d4d4d;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.join-secondary-option{
  text-align: center;
  font-family: "Poppins", sans-serif;
  width: 100%;
  margin-top: 1vh;
  color: #0247fe;
}

.join-secondary-option:hover{
  cursor: pointer;
}

#emailInput{
  width: 21vw;
}

@media (max-width: 786px) {
  .rec1-main {
    flex-direction: column-reverse;
    justify-content: flex-end;
    width: 100%;
  }
  .rec1-img {
    height: 40%;
    width: 100%;
  }
  .rec1-img img {
    height: 100%;
  }
  .rec1-content {
    width: 100%;
    height: 70%;
  }
  .rec1-content-header {
    padding-top: 2vh;
    font-size: 7.8vw;
    width: 100%;
  }
  .rec1-content-subtext {
    width: 100%;
    font-size: 3.8vw;
  }
  .mobile-break {
    height: 3vh;
  }

  .rec1-section-2 {
    flex-direction: column;
    align-items: center;
    height: 150vw;
    width: 100%;
    margin-top: 5vh;
    padding: 0%;
  }

  .rec1-signup-section {
    height: 100%;
    width: 100%;
  }

  .social-signup {
    width: 100%;
    height: 40%;
  }

  .rec1-join-box {
    margin-top: 10%;
    width: 100%;
    height:fit-content;
    flex-direction: column;
    align-items: normal;
  }

  .input-div{
    width: 100%;
  }

  .rec1-join-box input {
    width: 100%;
    height:3em;
    border-radius: 5px;
  }

  #emailInput{
    width: 93%;
  }

  .rec1-join-box input::placeholder {
    font-size: 3vw;
  }

  .rec1-join-box button {
    width: 100%;
    margin: 0%;
    margin-top: 2vh;
    height: 50%;
    padding: 2%;
    font-size: 1em;
    align-self: center;
  }

  .SocialSignInButton {
    width: 80%;
    height: 30%;
    font-size: 3vw;
  }
  .rec1-stat-section {
    height: 30%;
    width: 100%;
  }
  .stat-box {
    width: 100%;
    font-size: 3.5vw;
  }

  .rec1-mintosh-logo {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    font-size: 4vw;
    margin-top: 5vw;
  }
  .rec1-mintosh-logo img {
    width: 25vw;
    height: 25vw;
  }
  .alert-message {
    font-size: 3vw;
  }

  .stat-box {
    flex-direction: column;
  }

  .stat-box-text {
    margin-left: 0px;
    margin-top: 10px;
  }

  .social-signup h3 {
    margin-block-start: 0;
    margin-block-end: 0;
  }
}
