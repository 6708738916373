.Navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 87%;
  padding-left: 5%;
  padding-right: 8%;
  height: 13vh;
  background-color: #e6edff;
}

.Navbar #navLogoBox {
  height: 50%;
}

#navLogoBox img {
  height: 100%;
}

.navLinks {
  display: flex;
  justify-content: space-between;
  width: 26%;
}

.Navbar a {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

.navLinks h1 {
  font-weight: 500;
  font-size: 1.44vw;
  letter-spacing: 1px;
  color: #4d4d4d;
}

@media (max-width: 786px) {
  .Navbar img {
    height: 60%;
    max-height: 6vh;
  }
  .navLinks {
    width: 40%;
  }
  .navLinks h1 {
    font-size: 3vw;
  }

  .Navbar{
    height: 7vh;
  }
}
