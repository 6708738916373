.rec5 {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  padding-left: 8%;
  padding-right: 10%;
  padding-bottom: 3vw;
  width: 80%;
  height: 40vw;
  font-family: Poppins, sans-serif;
}

.rec5-img {
  display: flex;
  justify-content: center;
  width: 30%;
  height: 100%;
}

.rec5-img img {
  width: 100%;
  height: 70%;
  padding-top: 25%;
}

.rec5-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 50%;
}
.rec5-content-header {
  width: 100%;
  font-weight: bold;
  line-height: 120%;
  font-size: 2.5vw;
  color: #4d4d4d;
}

.rec5-content-subtext {
  padding-top: 2%;
  font-size: 1.1vw;
  color: #717171;
}
.rec5-join {
  border-width: 1px;
  border-radius: 8px;
  height: 10%;
  width: 30%;
  font-weight: 500;
  font-size: 1.2vw;
  color: white;
  background-color: #717171;
}

@media (max-width: 786px) {
  .rec5 {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
    height: 220vw;
  }
  .rec5-img {
    width: 100%;
    height: 50%;
    padding-top: 10%;
  }
  .rec5-img img {
    width: 100%;
    height: 100%;
    padding-top: 0%;
  }
  .rec5-content {
    padding-top: 10%;
    width: 100%;
    height: 60%;
    justify-content: flex-start;
  }
  .rec5-content-header {
    font-size: 5.8vw;
    width: 100%;
  }
  .rec5-content-subtext {
    font-size: 3.3vw;
    width: 100%;
  }
  .rec5-join {
    width: 50%;
    min-height: 7vh;
    font-size: 3vw;
  }
}
