.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal {
  border-radius: 10px;
  display: flex;
  width: 40%;
  flex-direction: column;
  background-color: #fff;
  padding: 3%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.modal-close {
  width: 5%;
  padding-left: 95%;
  background-color: white;
  color: black;
  margin-bottom: 5%;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 1.5vw;
  border: none;
}

.modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 1.5vw;
  color: #4D4D4D;
}

.modal-header button {
  width: 40%;
  color: white;
  background-color: #717171;
  border: none;
  margin-top: 3%;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1vw;
}

.modal-form {
  width: 90%;
  padding: 5%;
  display: flex;
  flex-direction: column;
}

.modal-form input {
  height: 3vw;
}

.modal-form button {
  height: 3vw;
  width: 30%;
  margin-left: 70%;
  margin-top: 2%;
  color: white;
  background-color: #717171;
  border: none;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1vw;
}


.modal-edit-text{
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 0.8em;
  cursor: pointer;
  color: #0247FE;
}

.modal-header-text-bottom{
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-top: 10px;
  font-size: 0.7em;
  color: #4D4D4D;
}

.modal-body{
  margin-top: 2em;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-body-sub-section{
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  align-items: center;
}

.modal-not-got-otp-text{
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1em;
}

.modal-resend-otp-text{
  margin-top: 10px;
  font-size: 1em;
  color: #0247FE;
  font-weight: 600;
}

.modal-verify-btn{
  margin-top: 2em;
  font-size: 1em;
  background-color: #0247FE;
  border: none;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  color: white;
  border-radius: 4px;
  font-weight: 600;
}

.modal-success-body{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.check-img{
  width: 20%;
}

.modal-success-text-section{
  font-family: "Poppins", sans-serif;
  margin-top: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-success-text-top{
  font-weight: 900;
  font-size: 1.5em;
  color: #4D4D4D;

}

.modal-success-text-bottom{
  font-weight: 400;
  font-size: 1em;
  margin-top: 1em;
  color: #0247FE;

}
@media(max-width: 768px){
  .modal{
    width: 90%;
    padding: 5%;
    box-sizing: border-box;
  }
  .modal-edit-text{
    font-size: 2em;
  }

  .modal-header-text-top{
    font-size: 2.5em;
  }

  .modal-header-text-middle{
    font-size: 2.5em;
  }

  .modal-header-text-bottom{
    font-size: 1.5em;
  }

  .modal-not-got-otp-text{
    font-size: 0.7em;
  }

  .modal-resend-otp-text{
    font-size: 0.7em;
  }

  .modal-verify-btn{
    font-size: 0.8em;
  }

  .modal-close{
    font-size: 1.5em;
  }

  .modal-success-text-section{
    margin-top: 2em;
  }

  .modal-success-text-bottom{
    font-size: 0.9em;
    text-align: center;
  }
}