.footer {
  padding-top: 2%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  height: 22vw;
  background-color: #0247fe;
  font-family: Poppins, sans-serif;
}

.footer-header {
  font-size: 2.2vw;
  font-weight: 600;
}

.footer-subhead {
  font-size: 1.1vw;
}

.footer-social {
  display: flex;
  width: 20%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 25%;
}
.footer-social img {
  height: 2vw;
}
.footer-copyright {
  display: flex;
  height: 10%;
  font-size: 1.1vw;
}
.footer-privacy {
  padding-top: 0.5%;
  color: white;
  display: flex;
  height: 10%;
  text-decoration: none;
  font-size: 1.1vw;
}

@media (max-width: 786px) {
  .footer {
    height: 50vw;
    align-items: center;
    padding: 5%;
    width: 90%;
  }
  .footer-header {
    font-size: 6vw;
  }
  .footer-social {
    width: 50%;
  }
  .footer-social img {
    height: 4vw;
  }
  .footer-subhead {
    font-size: 4vw;
  }
  .footer-copyright {
    font-size: 3vw;
  }
  .footer-privacy {
    font-size: 3vw;
  }
}
