.rec2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 38vw;
  padding-top: 5vh;
  font-family: Poppins, sans-serif;
  background-color: #e6edff;
}
.rec2-header-welcome {
  color: rgb(0, 214, 0);
  font-weight: 500;
  font-size: 3vh;
}
.rec2-header-what {
  font-size: 2.2vw;
  font-weight: 900;
  color: #4d4d4d;
  margin-bottom: 2%;
}

.rec2-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.rec2-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 20vw;
  background-color: white;
  border-radius: 8px;
  padding: 1vw;
}
.rec2-card img {
  padding-top: 10%;
  width: 20%;
  height: 15%;
}

.rec2-card-header {
  padding-top: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  font-size: 1.8vw;
  color: #4d4d4d;
}
.rec2-card-subtext {
  color: #717171;
  padding-top: 5%;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 1vw;
  width: 80%;
}

@media (max-width: 786px) {
  .rec2 {
    width: 100%;
    height: 310vw;
  }
  .rec2-header-welcome {
    font-size: 4vw;
  }
  .rec2-header-what {
    font-size: 6vw;
  }
  .rec2-box {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .rec2-card {
    width: 87%;
    margin-top: 2vh;
    margin-bottom: 5vh;
    padding: 0%;
    padding-bottom: 10%;
  }
  .rec2-card-header {
    padding-top: 3%;
    font-size: 6vw;
    width: 65%;
    height: 30%;
  }
  .rec2-card-subtext {
    font-size: 4.2vw;
    width: 75%;
    line-height: 110%;
  }
  .rec2-card img {
    padding-top: 0%;
    height: 50%;
  }
}
