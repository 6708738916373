.rec3 {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  padding-left: 8%;
  padding-right: 10%;
  width: 80%;
  height: 40vw;
  font-family: "Poppins", sans-serif;
}

.rec3-img {
  display: flex;
  align-items: center;
  width: 30%;
  height: 100%;
}

.rec3-img img {
  width: 90%;
}

.rec3-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 50%;
}
.rec3-content-header {
  width: 100%;
  font-weight: bold;
  line-height: 120%;
  font-size: 2.5vw;
  color: #4d4d4d;
}

.rec3-content-subtext {
  padding-top: 2%;
  font-size: 1.1vw;
  color: #717171;
}
.rec3-join {
  border-width: 1px;
  border-radius: 8px;
  height: 10%;
  width: 30%;
  font-weight: 500;
  font-size: 1.2vw;
  color: white;
  background-color: #717171;
}

@media (max-width: 786px) {
  .rec3 {
    flex-direction: column;
    margin-top: 15vh;
    align-items: center;
    width: 80%;
    height: 235vw;
  }
  .rec3-img {
    width: 100%;
  }
  .rec3-img img {
    width: 100%;
  }
  .rec3-content {
    width: 100%;
    justify-content: flex-start;
    margin-top: 5vh;
  }
  .rec3-content-header {
    font-size: 5.8vw;
    width: 100%;
  }
  .rec3-content-subtext {
    font-size: 3.3vw;
    width: 100%;
  }
  .rec3-join {
    width: 50%;
    font-size: 3vw;
    min-height: 7vh;
  }
}
