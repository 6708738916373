.Terms {
  display: flex;
  flex-direction: column;
  padding: 7.5%;
  padding-top: 3%;
  width: 85%;
  font-family: "Poppins", sans-serif;
}

.Terms h1 {
  font-size: 3em;
}

.Terms h2 {
  margin-top: 10vh;
}

.Terms-Join {
  display: flex;
  margin-top: 2%;
  flex-direction: column;
  justify-content: center;
  background-color: #eff1f8;
  width: 100%;
  padding-bottom: 5%;
}

.Terms-Join h2 {
  margin-left: 4vw;
  font-size: 2.3em;
  font-weight: 800;
  color: #4d4d4d;
}

.Terms-Join h3 {
  color: #4d4d4d;
  margin-left: 4vw;
  font-weight: 400;
  font-size: 2vw;
}

.Terms-Join button {
  margin-left: 4vw;
  width: 30%;
  font-weight: 700;
  font-size: 1.5em;
  border: 0;
  color: white;
  border-radius: 8px;
  padding: 0.5em;
  background-color: #0247fe;
  height: fit-content;
}

.Terms-Content{
  text-align: justify;
}
@media (max-width: 786px) {
  .Terms {
    font-size: 2vw;
  }
  .Terms-Join {
    padding-bottom: 7%;
    margin-top: 10%;
  }

  .Terms-Join h2 {
    margin-top: 10%;
  }

  .Terms-Join h3{
    font-size: 1.5em;
    margin-top: 0;
  }

  .Terms-Join button {
    width: 50%;
    font-size: 1.7em;
    border-radius: 3px;
  }

  .Terms-Content h2{
    margin-top: 10%;
  }
}
