.Blog-List {
  width: 100%;
  height: 100%;
  font-family: "Poppins", sans-serif;
}
.Blog-List-Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  color: white;
  background-image: url("../../assets/rainbow-bg.webp");
  background-position: center;
  background-size: cover;
  height: fit-content;
  padding-top: 4vw;
  padding-bottom: 4vw;
  width: 100%;
}

.Blog-List-Header-Title {
  font-weight: 800;
  font-size: 3.6vw;
  height: fit-content;
  line-height: 98%;
}
.Blog-List-Header-SubTitle {
  text-align: center;
  font-weight: 400;
  font-size: 1.4vw;
  width: 35%;
}

.Blog-List-Grid {
  padding-top: 4vh;
  width: 85%;
  padding-left: 7.5%;
  padding-right: 7.5%;
}

.Blog-List-Latest {
  width: 100%;
}
.Blog-List-Latest h1 {
  font-weight: 600;
  font-size: 2.4vw;
  line-height: 100%;
}

.Blog-List-Latest-Blog {
  height: fit-content;
  width: 100%;
  display: flex;
  text-decoration: none;
  flex-direction: row;
  align-items: center;
  padding: 1.5%;
  border: 1px solid #e7e7e7;
  border-radius: 2vh;
}


.Blog-List-Latest-Blog img {
  height: 97%;
  max-width: 55%;
  border-radius: 2vh;
}
.Blog-List-Latest-Blog-Content {
  height: 80%;
  display: flex;
  flex-direction: column;
  width: 90%;
  
  padding-left: 5%;
  padding-right: 5%;
}
.Blog-List-Latest-Blog h1 {
  width: 100%;
  color: #000;
  font-weight: 600;
  font-size: 3vw;
  text-align: left;
}
.Blog-List-Latest-Blog h2 {
  width: 100%;
  height: 60%;
  font-weight: 400;
  font-size: 1.5vw;
  text-align: left;
  color: #4d5053;
}

.Blog-List-Latest-Blog-Content-Footer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 20%;
}
.Blog-List-Latest-Blog-Content-Footer h3 {
  color: #808080;
  font-size: 1.2vw;
  font-weight: 400;
}
.Blog-List-Latest-Blog-Content-Footer img {
  height: 70%;
}

.Blog-List-Latest-Blog-arrow {
  width: 15%;
}

.Blog-List-Popular {
  margin-top: 5vh;
  width: 100%;
}
.Blog-List-Popular h1 {
  font-weight: 600;
  font-size: 2.4vw;
  line-height: 100%;
}
.Blog-List-Popular-Grid {
  display: grid;
  gap: 3%;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
}
.Popular-Card {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-decoration: none;
}

.Popular-Card h2{
  color: #000;
}
.Popular-Card img {
  width: 100%;
  height: 40%;
}

.Popular-Card p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: 400;
  color: #808080;
}

.Popular-Card h2 {
  padding-top: 1%;
  font-weight: 600;
  font-size: 2vw;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.Popular-Card-Date {
  border-bottom: solid #dcdcdc 1px;
  height: 8%;
  width: 100%;
  padding-top: 3%;
  padding-bottom: 3%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.Popular-Card-Content {
  display: flex;
  flex-direction: column;
  height: 35%;
}
.Popular-Card-Date h3 {
  color: #808080;
  font-weight: 400;
  font-size: 1.2vw;
}

.Popular-Card a {
  text-decoration: none;
  color: black;
}

.Blog-List-Page {
  margin-top: 5vh;
  margin-bottom: 10vh;
  display: flex;
  width: 27%;
  padding-left: 36.5%;
  align-items: center;
  justify-content: space-between;
  height: 4vw;
}

.Blog-List-Page button {
  border-radius: 50%;
  height: 100%;
  width: 17%;
  border-width: 1px;
}

.Blog-List-Join {
  display: flex;
  margin-top: 5vw;
  margin-bottom: 5vh;
  flex-direction: column;
  justify-content: center;
  background-color: #eff1f8;
  width: 100%;
  padding-bottom: 5%;
  height: 15vw;
}

.Blog-List-Join h2 {
  margin-left: 4vw;
  font-size: 2.3vw;
  margin-bottom: 0;
  font-weight: 800;
  color: #4d4d4d;
}

.Blog-List-Join h3 {
  color: #4d4d4d;
  margin-top: 1vh;
  margin-left: 4vw;
  font-weight: 400;
  font-size: 2vw;
}

.Blog-List-Join button {
  margin-left: 4vw;
  width: 30%;
  font-weight: 700;
  font-size: 1.5vw;
  color: white;
  border: 0;
  border-radius: 8px;
  background-color: #0247fe;
  height: fit-content;
  padding: 0.5em;
  
}

@media (max-width: 786px) {
  .Popular-Card {
    height: 100%;
  }
  .Popular-Card h2 {
    padding: 0%;
    font-size: 2.5vw;
    -webkit-line-clamp: 2;
  }
  .Popular-Card p {
    font-size: 2vw;
  }
  .Popular-Card a {
    font-size: 2vw;
  }

  .Blog-List-Popular-Grid {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 10%;
  }
  .Blog-List-Join {
    padding-top: 7%;
    padding-bottom: 7%;
  }
  .Blog-List-Join button {
    font-size: 0.8em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 3px;
  }
}
