@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,900");

.App {
  font-family: "Poppins", sans-serif;
}

.Home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

button:hover {
  cursor: pointer;
}
