.Blog {
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 7.5%;
  padding-top: 5%;
  font-family: "Poppins", sans-serif;
}

.Blog h1{
  margin-block-end: 0;
}

.Blog-Main {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.Blog-Main-1 {
  width: 70%;
}
.Blog-Main-1 a {
  text-decoration: none;
  color: #6de4ea;
}
.Blog-Info {
  font-size: 1.5vw;
  margin-top: 2%;
  display: flex;
  align-items: center;
  height: fit-content;
  color: #00000080;
}

.Blog-Main-1 h1 {
  margin: 0%;
  margin-top: 3%;
  font-weight: 700;
  font-size: 2.8vw;
}
.Blog-Info span {
  color: #6de4ea;
  padding-right: 0.7vw;
}

.Blog-Image {
  margin-top: 3vh;
  width: 100%;
}

.Blog-Image img {
  width: 70%;
}
.Blog-Content {
  padding-bottom: 5%;
  padding-top: 5%;
  font-size: 1.5em;
  text-align: justify;
  margin-bottom: 10vh;
  border-bottom: 0.86px solid #6de4ea;
}

.Blog-Footer-Join {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #eff1f8;
  width: 100%;
  padding-bottom: 5%;
  height: 15vw;
}

.Blog-Footer-Join h2 {
  margin-left: 4vw;
  font-size: 2.3vw;
  margin-bottom: 0;
  font-weight: 800;
  color: #4d4d4d;
}


.Blog-Content{
  white-space: pre-line;
}

.Blog-Footer-Join h3 {
  color: #4d4d4d;
  margin-top: 1vh;
  margin-left: 4vw;
  font-weight: 400;
  font-size: 2vw;
}

.Blog-Footer-Join button {
  margin-left: 4vw;
  width: 30%;
  font-weight: 700;
  font-size: 1.5vw;
  color: white;
  border: 0;
  border-radius: 8px;
  background-color: #0247fe;
  height: 170%;
}
.Blog-Nav {
  display: flex;
  width: 45%;
  text-decoration: none;
  font-size: 1vw;
}



.Blog-Nav a {
  text-decoration: none;
  color: #6de4ea;
  margin-right: 2%;
}

.Blog-Nav span{
  margin-left: 2%;
}

@media (max-width: 786px) {

  .Blog{
    width: 85%;
  }
  .Blog-Table {
    height: 0%;
    width: 0%;
  }
  .Blog-Main {
    justify-content: center;
  }
  .Blog-Main-1 {
    width: 100%;
  }
  .Blog-Main-1 a {
    font-size: 2vw;
  }

  .Blog-Nav {
    width: 33%;
  }
  .Blog-Footer-Join {
    padding-top: 7%;
    padding-bottom: 7%;
    margin-top: 0;
  }
  .Blog-Footer-Join button {
    height: 60%;
    width: 30%;
  }

  .Blog-Info {
    font-size: 1em;
    margin-top: 5%;
  }

  .Blog-Main-1 h1{
    font-size: 1.5em;
  }

  .Blog-Content {
    font-size: 1em;
    margin-bottom: 5vh;
  }

  .Blog-Nav{
    font-size: 0.8em;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .Blog-Image img{
    width: 100%;
  }
}
